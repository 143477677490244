<template>
    <div class="reset-wrap">
        <div class="reset-content">
            <user-form 
                :formData="formData" 
                :formItems="formItems" 
                :formRules="formRules" 
                :formMsg="formMsg"
                @handleMsg="handleMsg"
                @onSubmit="onSubmit"
            >
                <template v-slot:codeSlot>
                    <codeButton :mobile="formData.mobile"/>
                </template>
            </user-form>
        </div>
    </div>
</template>

<script>
    import { validateElePhone, validateCode, validateElePassword, validaMobile } from '@/utils/valida'
   import { removeStroage } from '@/utils/common'
    import userForm from '@/components/userForm'
    import codeButton from '@/components/codeButton'
    export default {
        components: {
            userForm,
            codeButton
        },
        data() {
            return {
                formData: {
                    mobile: '',
                    code: '',
                    password: '',
                    confirm_password: ''
                },
                formItems: [
                    {
                        label: '账号',
                        type: 'input',
                        placeholder: '请输入手机号',
                        key: 'mobile',
                    },
                    {
                        label: '验证码',
                        type: 'input',
                        placeholder: '请输入验证码',
                        key: 'code',
                        slotName: 'codeSlot',
                    },
                    {
                        label: '密码',
                        type: 'input',
                        placeholder: '6-16位密码，区分大小写',
                        isPassword: true,
                        key: 'password',
                    },
                    {
                        label: '确定密码',
                        type: 'input',
                        placeholder: '请再次输入密码',
                        isPassword: true,
                        key: 'confirm_password',
                    },
                ],
                formRules: {
                    mobile: [
                        { validator: validateElePhone, trigger: 'blur' }
                    ],
                    code: [
                        { validator: validateCode, trigger: 'blur' }
                    ],
                    password: [
                        { validator: validateElePassword, trigger: 'blur' }
                    ],
                    confirm_password: [
                        { validator: this.vadlidateConfirm, trigger: 'blur' }
                    ]
                },
                formMsg: [
                    {
                        label: '已有账号？点击',
                        title: '登录',
                        callbackName: 'loginClick'
                    }
                ]
            }
        },
        methods: {
            vadlidateConfirm(rule, value, callback) {
                if(!value.trim()) {
                    callback(new Error('请输入确定密码!'));
                } else if (this.formData.password != value) {
                    callback(new Error('两次输入密码不一致!'));
                } else {
                    callback();
                }
            },
            handleMsg(e) {
                this[e]()
            },
            loginClick() {
                this.$router.replace({ path: '/' })
            },
            async onSubmit(e) {
                const result = await this.$postHttp(this.$api.postForgetPassword, e)
                if(result.code == 1) {
                    validaMobile()? this.$toast('重置密码成功,请重新登录！') : this.$successPrompt('重置密码成功,请重新登录！')
                    removeStroage('token')
                    removeStroage('isExite')
                    this.$store.commit('SET_EXITE_BUT', false)
                    setTimeout(() => {
                        this.loginClick()
                    }, 800);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.reset-wrap{
    width: 100%;
    height: 100%;
    background: url('../assets/image/pc_login_bg.png') no-repeat;
    background-size: cover;
    .reset-content{
        overflow: hidden;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        ::v-deep .el-form{
            .fomr-msg{
                justify-content: center;
            }
            .el-form-item:nth-child(3){
                .el-form-item__content{
                    @include flex();
                }
            }
            .el-form-item:last-child{
                .el-form-item__content{
                    text-align: center;
                }
            }
        } 
    }
}
@media screen and (max-width:1024px){
    .reset-wrap{
        background: url('../assets/image/mobile/mobile_bg.png') no-repeat;
        background-size: 100% 250px;
        background-position: 0 0;
        overflow: hidden;
        .reset-content{
            width: 100%;
            position: inherit;
            transform: inherit;
            margin-top: 96px;
        }
    }
}
@media screen and (max-width:375px){
    .reset-wrap{
        .reset-content{
            .user-form{
                ::v-deep .el-form{
                    .el-form-item{
                        margin-bottom: 18px;
                        .el-form-item__label{
                            line-height: 35px;
                        }
                        .el-form-item__content{
                            line-height: 35px;
                            .el-input__inner{
                                height: 35px;
                                line-height: 35px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width:320px){
    .reset-wrap{
        .reset-content{
            margin-top: 70px;
            .user-form{
                ::v-deep .el-form{
                    padding: 20px 24px 24px 24px;
                    height: calc(100vh - 200px);
                    .el-form-item{
                        margin-bottom: 16px;
                        .el-form-item__label{
                            line-height: 32px;
                        }
                        .el-form-item__content{
                            line-height: 32px;
                            .el-input__inner{
                                height: 32px;
                                line-height: 32px;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
<style>
@media screen and (max-width: 1024px) {
    .el-message{
        min-width: 300px;
    }
}
</style>